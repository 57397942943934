/* .product-catalog-container{
    background-color: red;
} */

.product-catalog-banner{
    width: 100%;
    height: 300px;
    /* background-color: grey; */
    margin-bottom:50px;
    border: 1px solid grey;
}

.product-catalog-banner img{
    width: 100%;
    height: 100%;
}

/* .product-catalog-buy-component{
    background-color: grey;
} */

.product-catalog-buy-component-cell1{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}

.product-catalog-buy-component-cell1 h3{
    outline: 1px solid grey;
    /*margin-left: 10px;
    margin-right: 10px; */
    height:48px;
    width: 100px;
    margin: 0px;
    font-size: 30px;
    text-align: center;
    padding-top: 5px;
    /*padding-left: 30px;
    padding-right: 30px;*/
}

.btn-primary.add-product{ 
    background:$azul-botones-general;
    color: white;
    border:none;
    width: 20%;
    height:50px;
    border-radius: 50px;
    font-size:17PX;
    &:hover{
        background:$azul-oscuro-texts-and-buttons;
    }
}

.add-product{
    margin-left: 30px;
    margin-right: 30px;
}

.name-product-catalog{
    color: #323366;
    font-size: 50px;
}

.product-catalog-review{
   display: flex;
   flex-direction: column;
}

.product-catalog-gallery{
    width: 100%;
    /* background-color: grey; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.product-catalog-gallery img{
    width: 260px;
    height: 200px;
    margin: 10px;
    border: 1px solid $gris-claro-lineas;
}

.product-catalog-description{
    font-size: 22px;
}