.about__container-general{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content-about{
    text-align: center;
    padding: 15px;
    padding-top: 50px;
}

.content-about h1 {
    color: $verde-general;
}

.content-about p {
    font-size: 20px;
}

.content-about i {
    padding: 40px;
    font-size: 30px;
    color: #E3E3E3;
}