.fixed-header{
    font-family: $font-family;
    width: 100%;
}

.header__container{
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 150px;
    align-items: center;
    // background-color: grey;
}

.header__icon{
    img{
        width: 265px;
        height: 125px;
    }
}

.header__main-selector{
    
    width: 50%;
    display: flex;
    justify-content: flex-end;
    nav{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 10px;
        nav{
            display: flex;
            flex-direction: column;
        }
    }
}

.header_hiden-menu-user{
    right:-30px;
    top:100px;
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.header__bottom-line{
    width: 100%;
    height: 1px;
    background-color: $gris-claro-lineas;
    // margin-bottom: 10px;
}