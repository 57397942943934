// Son los estilos para lo que es Login y Registro

.login-container{
    padding-top: 5%;
    padding-bottom: 10%;

}

.login-titulo h1{
    color : $verde-general;
    text-align: center;
    margin: 10px;
}

.login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-form-container{
    display: flex;
    justify-content:center;
}

.login-form-components{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content:center;
}

.login-titulo h1{
    text-align: center;
}

.login-parafo{
    text-align: center;
}

.input-form{
    border: 1px solid grey;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 40px;
    padding-left: 40px;
}

.login-form-components button{
    margin-top: 20px;
    position: relative;

    background:$azul-botones-general;
    color: white;
    border:none;
    width: 40%;
    height:50px;
    border-radius: 50px;
    font-size:17PX;
    &:hover{
        background:$azul-oscuro-texts-and-buttons;
    }
}

.login-form-components i{
    color: $gris-claro-lineas;
    position: absolute;
    margin-top: 22px;
    margin-left: 10px;
}

.form-privacy-policies{
    display: flex;
    flex-direction: row;
}

.form-privacy-policies input{
    margin: 10px;
}

.login-form-row-options{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    button{
        width: 170px;
    }
}

.nombre-form-title{
    font-weight: bold;
    margin-top: 15px;
}