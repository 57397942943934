.garden__container-general{
    width: 100%;
    margin-top: 70px;
    margin-bottom: 70px;
}

.garden__first-window{
    margin-top: 80px;
    margin-bottom: 80px;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-evenly;

    h1{
        text-align: center;
        color: $verde-general;
    }

    p{
        text-align: center;
        font-size: 18px;
    }

    button{
        padding: 13px;
       /* width: 330px;*/
        background:$azul-botones-general;
        color: white;
        width: 35%;
        height:50px;
        border-radius: 50px;
        font-size:17PX;
        &:hover{
            background:$azul-oscuro-texts-and-buttons;
        }
    }
}

.garden__device-list{
    display: flex;
    flex-wrap: wrap;
}

.garden__device-card-device{

    border: 1px solid $gris-claro-lineas;
    margin: 20px;
    width: 340px;
    height: 340px;

    // display: flex;
    flex-direction: column;
    justify-content: center;

    transition: .5s ease;

    
    // &:hover{
    //     animation: pulse; /* referring directly to the animation's @keyframe declaration */
    //     animation-duration: 1s; /* don't forget to set a duration! */
    //     color: darken($color: white, $amount: 1);
    // }


}

.garden__device-card-image{
    height: 80%;
    width: 100%;
    img{
        width: 100%;
        height: 100%;
    }
}

.garden__device-card-name{
    border: 1px solid;
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgrey;
    h5{
        text-align: center;
    }

    background-color: $griz-icons-footers;
    color:black;

    &:hover{
        background-color: $azul-botones-general;
        color:white;
    }
}

.garden__device-static-title{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}