.general-font-family{
    font-family: $font-family;
}

.main-component-content{
    padding-left: 13%;
    padding-right: 13%;
    font-family: $font-family;
}

.main-component-content-header{
    padding-left: 13%;
    padding-right: 13%;
    font-family: $font-family;
}

.fixed-content{
    margin-top: 150px;
}

.navbar-button-appeareance{
    background-color: white;
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    font-size: 20px;
    transition: .5s ease;


    /* efecto linea*/
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;

    &:hover{
        animation: headShake;
        color: $azul-botones-general;
        background-size: 100% 2px;
    }


    
}

.navbar-users-icon{
    color: $verde-general;
    font-size: 32px;
    transition: .5s ease;
    &:hover{
        color: $azul-botones-general;
    }
}

.navbar-console-button-appeareance{
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    font-size: 20px;

    transition: .5s ease;

    &:hover{
        animation: headShake;
        color: $azul-oscuro-texts-and-buttons;
    }
}

.general-buttons-appeareance{
    background-color: $azul-botones-general;
    color: white;
    border: none;
}

.space-top-botton-elements{
    margin-top: 15px;
    margin-bottom: 15px;
}

.general-title-appeareance{
    color: $verde-general;
}

.no-link-appeareance{
    text-decoration: none;
    color: black;
}

.handClickActive{
    cursor: pointer;
}

.general-p-format{
    font-size: 18px;
}

.icon-button-grey-style{
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    color: $gris-claro-lineas;
    font-size: 30px;

    transition: .5s ease;

    &:hover{
        animation: headShake;
        color: $azul-oscuro-texts-and-buttons;
    }
}


.icon-button-green-style{
    text-decoration: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    color: $verde-general;
    font-size: 30px;

    transition: .5s ease;

    &:hover{
        animation: headShake;
        color: $azul-oscuro-texts-and-buttons;
    }
}