.consolesettings__general-container{
    display: flex;
    flex-direction: column;
}

.consolesettings__title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
        color: $verde-general;
    }

    p{
        text-align: center;
        font-size: 18px;
    }
}

.consolesettings__config-options-general{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.consolesettings__config-options-inputs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80%;
    background-color: $gris-claro-lineas;
    margin-bottom: 30px;

    button {
        margin-right: 30px;
        margin-left: 30px;
    }
}

.consolesettings__config-options-title{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: $azul-botones-general;

    .consolesettings__config-options-p-container{
        display: flex;
        background-color:darkgray;
        width: 30%;
        height: 100%;
        align-items: center;
        align-content: center;
        p{
            font-size: 20px;
            margin-top: 5%;
            width: 100%;
            height: 100%;
            color: white;
            text-align: center;
        }
    }

    .consolesettings__config-option-input-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .input-console{
            background-color:transparent;
            border:none;
            height: 60%;
            width: 90%;
            margin-right: 20px;
            text-align: center;
            color: white;
            font-size:20px;
        }

        .inputfalse{
            border:2px solid #525252;
        }
    }
}


.consolesettings__config-options-selector{
    width: 80%;
    display: flex;
    flex-wrap: wrap;

}

.consolesettings__arrow-console-conf{
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 20px solid $azul-botones-general;
}

.consolesettings__output-selector{
    margin: 5px;
    width:300px;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    background-color:lightgrey;
   
    border-radius: 15px;
    background-color:#E8E8E8;

    transition: 1s ease;

    &:hover{
        background-color:darkgray;
    }

    &:focus{
        background-color: red;
    }

    img{
        margin-left: 10px;
        margin-right: 10px;
        width: 55px;
        height: 55px;
    }

    p{
        margin-top: 4%;
    }
}

.salidaSel0sel0{
    background-color: $azul-botones-general;
    p{
        color: white;
    }
}

.salidaSel1sel1{
    background-color: $azul-botones-general;
    p{
        color: white;
    }
}

.salidaSel2sel2{
    background-color: $azul-botones-general;
    p{
        color: white;
    }
}

.salidaSel3sel3{
    background-color: $azul-botones-general;
    p{
        color: white;
    }
}

.salidaSel4sel4{
    background-color: $azul-botones-general;
    p{
        color: white;
    }
}

.salidaSel5sel5{
    background-color: $azul-botones-general;
    p{
        color: white;
    }
}

.salidaSel6sel6{
    background-color: $azul-botones-general;
    p{
        color: white;
    }
}



.modfalse{
    pointer-events: none;
    background-color: #525252;
    transition: 1s ease;
    &:hover{
        animation: none; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 1s; /* don't forget to set a duration! */
    }
}

.consolesettings__output-configuration-container{
    width: 90%;
    height: 300px;
    border: 2px solid;
    border-color: lightgrey;
    border-radius: 20px;
    padding-top: 40px;
    padding-bottom: 40px;

    .consolesettings_output-configuration-type-selector{
        margin-left: 30px;
        margin-right: 30px;
        select{
            width:100%;
            text-align-last: center;
            height: 50px;
            background-color: $azul-botones-general;
            color: white;

            option{
                height: 50px;
                background-color: white;
                color: black;
            }
        }

    }
}

.consolesettings__pump-selected-row1{
    margin-top: 30px;
    
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

    
    label{
        margin:5px;
    }

    input {
        margin:5px;
        padding:5px;
    }

    input{
        width: 90px;
        height: 50px;
        background-color:$azul-botones-general;
        color: white;
    }
}

.consolesettings__pump-selected-row2{
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;

    button{
        border:none;
        border-radius:50px;
        width: 120px;
        height: 50px;
        background-color:$azul-botones-general;
        color: white;
    }
}