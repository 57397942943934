.navbar__console-container{
    background-color: $verde-general;
    height: 60px;
}

.navbar__console-content{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navbar__console-user-name{
    width: 30%;
    margin-right: 30px;
    p{
        padding-left: 10px;
        margin: auto;
        height: 100%;
        color: white;
        font-size: 20px;
    }
}

.notification-dot-navbar{
    font-size: 10px;
    color: $azul-botones-general;
}

@media (max-width: 900px){
    .navbar__console-container{
        height: auto;
    }
}