.icon-main-footer{
    width:140px;
    height: 160px;
}

.footer-body1{
    padding: 5px;
    background-color:$griz-icons-footers ;
}

.reders-sociales{
    border-top: 1px inset $gris-claro-lineas;
    background-color:$griz-icons-footers;
}