.general-container-contact{
    margin: 120px;
}

.general-container-contact h1 {
    color : $verde-general;
    text-align: center;
    margin: 30px;
}

.general-container-contact p {
    text-align: center;
    margin: 30px;
    font-size : 20px;
}

.contact-form {
    margin-left : 300px;
    margin-right : 300px;
    display: flex;
    flex-direction : column;
    font-weight: bold;
}


