.carousel__container-general{
  margin-left: -18%;
  width: 135.6%;
  height: 400px;
  overflow: hidden;

  img{
    width: 100%;
    animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
  }
}