// Declare aquí las variables globales de la vista
$verde-general : #ced94f;
$azul-botones-general : #41bac1;
$griz-icons-footers : #E3E3E3;

$gris-claro-lineas : #C2C2C2;

$azul-oscuro-texts-and-buttons : #323366;

//COLORES SEGUN TIPO DE ALERTA O MENSAJE
$success-color : #a1d890;
$message-color :#c1cccc;
$info-color : #4cb2ec;
$warning-color : #df8319;

// Fonts
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;