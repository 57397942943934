.messagesconsole__general-container{
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.messagesconsole__general-header-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.messagesconsole__general-message-item-container{
    display: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    .messageconsole__message-item{
        border: 2px solid $gris-claro-lineas;
        border-radius: 15px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 19px 25px 0px 10px;

        .row-message-item{
            display: flex;
            flex-direction: row;

            .date-format-p{
                padding-left: 30px;
                color: $gris-claro-lineas;
            }
        }

        p{
            width: 85%;
        }

        i{
            margin-left: 4%;
        }

        .notification-dot-item{
            margin-right: 30px;
            padding-top: 5px;
        }
    }
}

//BACKGROUND HANDLER
.SUCCESS{
    color: $success-color;
}

.MESSAGE{
    color: $message-color;
}

.INFO{
    color: $info-color;
}

.WARINING{
    color: $warning-color;
}
