.container-products{
    margin: 30px;
}

.title-products-hydroponic-line{
    color: $verde-general;
    margin-bottom: 40px;
}

.cardboard-product {
    animation: fadeInUpBig; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
    width : 400px;
    height : 550px;
    display: flex;
    flex-direction : column;
    align-items: center;
    // margin-bottom: 100px;
    /* background-color:grey; */
}

.button-buy{
    width: 50%;
    height:50px;
    background-color: $azul-botones-general;
    border-radius: 50px;
    border: none;

    &:hover{
      background-color: $azul-oscuro-texts-and-buttons;  
    }
}

.image-product-component {
    width: 100%;
    height: 50%;
    /* background-color: yellow; */
    position: relative;
}

.image-product-component button{
    position: absolute;
    top: 70%;
    left: 70%;
}

.image-product-component img{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 100%;
    border: 1px solid $gris-claro-lineas;

}

.name-product{
    color: $azul-oscuro-texts-and-buttons;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    /* padding-top: 5px; */
    /* padding-right: 90px; */
}

.price-product{
    color:$azul-oscuro-texts-and-buttons;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: 0px;
    /* padding-right: 90px; */
}

.btn-product{
    background: $verde-general;
    z-index: 1;
    /* left: 50%; */
    border-radius: 100%;
    border:none;
    width: 60px;
    height: 60px;
    color:$azul-oscuro-texts-and-buttons;
    font-size :25px;
    line-height:32px;
    padding: 10px;

    transition: .2s ease;

    &:hover{
      animation: pulse; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 1s; /* don't forget to set a duration! */
      color: darken($color: $azul-oscuro-texts-and-buttons, $amount: 1);
      background:$verde-general;
    }
}



/*Loading card*/

.wrapper {
    width: 320px;
    margin: 0 auto;
  }
  
  .card-loader {
    background-color: #fff;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08), 0 -1px 3px 0 rgba(0, 0, 0, 0.06);
    padding: 8px;
    position: relative;
    border-radius: 2px;
    margin-bottom: 0;
    height: 300px;
    overflow: hidden;
  }
  .card-loader:only-child {
    margin-top: 0;
  }
  .card-loader:before {
    content: "";
    height: 110px;
    display: block;
    background-color: #ededed;
    box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
  }
  .card-loader:after {
    content: "";
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-name: loader-animate;
    animation-timing-function: linear;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#00ffffff",GradientType=1 );
  }
  
  @keyframes loader-animate {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
