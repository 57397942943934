.home__general-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.midle-text{
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 15pt;
}

.centro-de-conocimiento{
    margin-left: -18%;
    width: 135.6%;
    background-color: $azul-botones-general;
    color: white;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.btn-light{
    width: 15%;
    height:60px;
    color: $azul-botones-general;
    border-radius: 50px;
    border: none;
    font-size:20px;

    &:hover{
        color: white;
        background-color: $azul-oscuro-texts-and-buttons;
    }
}