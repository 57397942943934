.homeconsole__container-general{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.homeconsole__banner-image{
    width: 100%;
    img{
        width: 100%;
    }
}

.homeconsole__body-content{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3{
        color: $verde-general;
        text-align: center;
        font-size: 38px;
    }

    p{
        font-size: 18px;
        text-align: center;
        margin-left: 10%;
        margin-right: 10%;
    }
}